setTimeout(function() {
   $('body').addClass('loaded');
}, 500);

$('.contact-btn').on('click', function() {
  $('html, body').animate({
      scrollTop: ($('footer').offset().top - 300)
  }, 500);
})

$(document).scroll(function() {
  // var threshold = $('.our-focus-areas div:first-of-type').offset().top - $(window).scrollTop();

  // if (threshold < 700) {
  //   $('.our-focus-areas div:first-of-type').addClass('visible');
  // }
  // if (threshold < 600) {
  //   $('.our-focus-areas div:last-of-type').addClass('visible');
  // }
})